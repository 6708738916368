<template>
  <div v-if="!loading && products && products.length" class="asf-product-slider" v-e2e="`${title}-slider-carousel`">
    <AsfHeading tag="h2" appearance="h1" class="asf-product-slider__title">
      {{ title }}
    </AsfHeading>
    <AsfSlider :options="mergedOptions" :count="products.length" :controls="products.length > productsPerView">
      <AsfSliderItem v-for="(product, index) in products" :key="product.id" class="asf-product-slide" inert>
        <AsfProductTile
          :no-swatches-highlighter="true"
          :product="product"
          @click="$emit('product:clicked', { product, position: index + 1 })"
        >
          <template #wishlist-icon="{ wishlistProduct }">
            <ProductWishlistIcon :aria-label="$t('wishlist.add')" :product="wishlistProduct" />
          </template>
          <template #ratings="{ ratingsProduct, ratingsLink }">
            <ProductRatings :product="ratingsProduct" :link="ratingsLink" />
          </template>
        </AsfProductTile>
      </AsfSliderItem>
    </AsfSlider>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, reactive } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { AgnosticProduct } from 'shared/types'
import { AsfSliderOptions, AsfSliderProps } from '@ui/types'

type ProductSliderProps = {
  title: TranslateResult | string
  products: ReadonlyArray<AgnosticProduct>
  loading: boolean
  sliderOptions?: AsfSliderProps['options']
}

export default defineComponent({
  name: 'ProductSlider',
  props: {
    title: { type: String as PropType<ProductSliderProps['title']>, required: true },
    products: { type: Array as PropType<ProductSliderProps['products']>, required: true },
    loading: { type: Boolean as PropType<ProductSliderProps['loading']>, required: true },
    sliderOptions: { type: Object as PropType<ProductSliderProps['sliderOptions']>, default: () => ({}) }
  },
  setup(props: ProductSliderProps) {
    const defaultOptions = reactive<AsfSliderOptions>({
      type: 'slider',
      rewind: false,
      gap: 20,
      peek: { before: 0, after: 90 },
      perView: 5,
      perSwipe: '|',
      breakpoints: {
        420: {
          perView: 1
        },
        1023: {
          perView: 3
        }
      }
    })

    const { isMediaMatch: isSmallOrMediumView } = useMediaQuery()
    const productsPerView = computed(() => (isSmallOrMediumView.value ? 3 : 5))

    const mergedOptions = computed<AsfSliderOptions>(() => ({
      ...defaultOptions,
      ...props.sliderOptions,
      ...(props.products.length > productsPerView?.value ? { type: 'carousel', rewind: true } : {})
    }))

    return { mergedOptions, productsPerView }
  }
})
</script>
<style lang="postcss">
.asf-product-slider {
  @apply my-10;
}

.asf-product-slide {
  @apply mx-2.5;

  max-width: 13.75rem;

  .asf-slider--slider &,
  .asf-slider--carousel & {
    @apply max-w-none;
  }
}

.asf-product-slider__title {
  @apply text-center mb-9;
}
</style>
